.MuiDialog-paper.MuiDialog-paperScrollPaper {
	border-radius: 24px;
	/* height: 519px; */
	height: auto;
	width: 600px;
	align-items: flex-start;
	padding: 24px 24px 0 24px;
	font-family: 'Nunito Sans', sans-serif;
	box-sizing: border-box;
}

.dropzone {
	text-align: center;
}

.dropzone_container {
	background: linear-gradient(0deg, rgba(55, 234, 158, 0.08), rgba(55, 234, 158, 0.08)), #ffffff;
	border: 1px dashed #21da8c;
	border-radius: 24px;
	box-sizing: border-box;

	/* Auto layout */

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 8px 0px;
	gap: 10px;
	isolation: isolate;

	width: 552px;
	height: 315px;
}

.syncbutton {
	display: flex;
	justify-content: space-between;
	padding-top: 24px;
}

.mappingSelection {
	display: flex;
	margin: 5px 8px;
}

.labels {
	border: 1.5px solid #a0a0a0;
	border-radius: 5px;
	margin: 5px 2px;
	color: #03030399;
	font-size: 14px;
	width: 256px;
	box-sizing: border-box;
	padding: 2px 4px;
}

.MuiSelect-outlined {
	color: #03030399;
	/* border: 1.5px solid #03030399!important; */
	width: 256px !important;
	box-sizing: border-box;
	/* padding: 2px 4px; */
}

.MuiOutlinedInput-root {
	height: 26px !important;
	margin: 5px 2px !important;
	font-family: 'Nunito Sans', sans-serif !important;
	font-size: 14px !important;
}

.MuiInputBase-input {
	padding: 2px 4px !important;
}

.MuiFormControl-root {
	width: 256px !important;
}

.columnsfields {
	display: flex;
	justify-content: space-around;
	font-weight: 700;
	font-size: 11px;
	line-height: 150%;
	color: #a0a0a0;
}

.filenamebox {
	display: flex;
	justify-content: space-between;
}

.parasubtext {
	color: #030303;
	opacity: 0.5;
	font-size: 12px;
}

.filename {
	font-weight: 400;
	font-size: 16px;
	line-height: 150%;
	color: #21da8c;
}

.mainColumnContainer {
	display: flex;
	flex-direction: column;
	width: 100%;
	text-align: center;
	/* overflow: hidden; */
}

div[name='type_choice'] {
	margin: 0.5rem 0 1rem 0;
}

div[name='crm_type'] {
	margin: 0 0 1rem 0;
}

::-webkit-scrollbar-track {
	background-color: #ffffff !important;
}

::-webkit-scrollbar {
	width: 7px;
	height: 6px;
	background-color: #ffffff !important;
}

::-webkit-scrollbar-thumb {
	background-color: rgba(0, 0, 0, 0.3);
	border-radius: 4px;
}

.actualColumns {
	height: 350px;
	overflow: scroll;
}

/* MuiInputBase-root MuiOutlinedInput-root Mui-focused Mui-focused MuiInputBase-formControl */
